var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "navbar navbar--cart" }, [
    _c("ul", { staticClass: "navbar__items" }, [
      _c(
        "li",
        { staticClass: "navbar__item navbar__logo--cart navbar__logo--margin" },
        [
          _c(
            "a",
            { on: { click: _vm.logoClicked } },
            [
              _c("icon", {
                staticClass: "navbar__logo-icon",
                attrs: { name: "logo-icon", original: "" },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "li",
        {
          staticClass: "navbar__step-indicator",
          class: {
            "navbar__step-indicator--done": _vm.cartStep > _vm.CartStep.Review,
            "navbar__step-indicator--active":
              _vm.cartStep === _vm.CartStep.Review,
          },
        },
        [
          _vm.cartStep > _vm.CartStep.Review
            ? _c("icon", {
                staticClass: "navbar__step-indicator__icon",
                attrs: { name: "check" },
              })
            : _c("span", [_vm._v("1")]),
          _vm._v("Review"),
        ],
        1
      ),
      _c(
        "li",
        {
          staticClass: "navbar__step-indicator",
          class: {
            "navbar__step-indicator--active":
              _vm.cartStep === _vm.CartStep.PaymentOptions ||
              _vm.cartStep === _vm.CartStep.PaymentForm,
          },
        },
        [_c("span", [_vm._v("2")]), _vm._v("Payment")]
      ),
      _vm._m(0),
      _c(
        "li",
        {
          staticClass:
            "navbar__item navbar__item--right navbar__step-indicator",
        },
        [
          _c("icon", {
            staticClass: "navbar__step-indicator__icon icon-lock",
            attrs: { name: "lock", original: "" },
          }),
          _vm._v("Secure Checkout"),
        ],
        1
      ),
      _c("li", { staticClass: "navbar__item h-hide-lte-mobile" }, [
        _c(
          "button",
          {
            staticClass: "btn btn--grey",
            on: {
              click: function ($event) {
                return _vm.router.back()
              },
            },
          },
          [_vm._v("BACK TO BROWSE")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "navbar__step-indicator" }, [
      _c("span", [_vm._v("3")]),
      _vm._v("Download"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }